import kaplay from "kaplay";
import { createPlayer } from "./entities/player";
import { createMenuScene } from "./scenes/menu";
import { createOptionsScene } from "./scenes/options";
import togglePause, { isPaused } from "./utils/togglePause";

kaplay({
  background: "000101",
  font: "sans-serif",
  crisp: true,
  global: true,
  height: 1080,
  width: 1920,
  stretch: true,
  letterbox: true,
  buttons: {
    jump: {
      keyboard: ["space", "up"],
      keyboardCode: "Space",
    },
    l: {
      keyboard: ["left", "a"],
      keyboardCode: "a",
    },
    r: {
      keyboard: ["right", "d"],
      keyboardCode: "d",
    },
  },
  scale: 1,
});

loadSprite("player", "assets/robot_blueBody.png");
loadSprite("enemy", "sprites/bean.png");
loadSprite("platform", "assets/grass.png");

const game = scene("game", () => {
  const SPEED = 450;
  const JUMP_FORCE = 1100;

  setGravity(2500);
  const level = addLevel(
    [
      "                          ",
      "                          ",
      "                          ",
      "        =                 ",
      "              =           ",
      "             =            ",
      "        =                 ",
      "               =          ",
      "   ===================    ",
      "                          ",
      "                          ",
      "                          ",
    ],
    {
      tileHeight: 128,
      tileWidth: 128,
      scale: 0.4,
      tiles: {
        "=": () => [sprite("platform"), area(), body({ isStatic: true })],
        "|": () => [
          color(0, 0, 0),
          rect(6, 128),
          area(),
          body({ isStatic: true }),
        ],
      },
    }
  );

  const player = createPlayer();
  const jumpText = add([text("Jumps: 0"), pos(10, 10), { value: 0 }, "hud"]);
  const healthText = add([
    text("Health: " + player.hp()),
    pos(player.pos),
    "hud",
  ]);

  function updateJumpText() {
    jumpText.text = `Jumps: ${player.jumps}`;
  }

  function updateHealthText() {
    healthText.value = player.health.hp();
    healthText.text = `Health: ${healthText.value}`;
  }

  function playerJump() {
    if (player.jumps <= player.maxJumps) {
      player.jump(JUMP_FORCE);
      player.jumps++;
      updateJumpText();
    }
  }

  onButtonDown("l", () => {
    if (isPaused) return;
    if (player.flipX !== true) {
      player.flipX = true;
    }
    player.move(-SPEED, 0);
  });

  onButtonDown("r", () => {
    if (isPaused) return;
    if (player.flipX !== false) {
      player.flipX = false;
    }
    player.move(SPEED, 0);
  });

  onButtonPress("jump", () => {
    playerJump();
  });

  const enemy = add([sprite("enemy"), pos(400, 50), area(), body(), "enemy"]);

  player.onGround(() => {
    player.jumps = 0;
    updateJumpText();
  });

  enemy.onUpdate(() => {
    const dir = player.pos.sub(enemy.pos).unit();
    enemy.move(dir.scale(100));
  });

  player.onUpdate(() => {
    camPos(player.pos);
  });

  onClick(() => {
    if (player.isColliding(enemy)) {
      destroy(enemy);
      addKaboom(enemy.pos);
    }
  });

  onKeyPress("escape", () => {
    togglePause(player.pos);
  });
});
createOptionsScene();
createMenuScene();

go("menu");
