export const createPlayer = () => {
  return add([
    sprite("player"),
    health(100),
    pos(50, 50),
    area(),
    body(),
    {
      jumps: 0,
      maxJumps: 2,
    },
  ]);
};
