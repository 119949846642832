export let isPaused = false;

function togglePause(position) {
  isPaused = !isPaused;

  // Get all game objects
  const allObjects = get();
  console.log(allObjects);
  allObjects.forEach((obj) => {
    // Toggle the paused state for all objects
    obj.paused = isPaused;

    // Pause or resume timers attached to objects
    if (obj.timer) {
      obj.timer.paused = isPaused;
    }
  });

  if (isPaused) {
    // Display pause menu
    const pauseMenu = add([
      rect(width(), height()),
      color(0, 0, 0),
      opacity(0.8),
      pos(position),
      anchor("center"),
      z(100),
      "pauseMenu",
    ]);

    add([
      text("PAUSED", { size: 32 }),
      pos(position),
      anchor("center"),
      color(255, 255, 255),
      z(101),
      "pauseMenu",
    ]);
  } else {
    // Remove pause menu
    destroyAll("pauseMenu");
  }
}

export default togglePause;
