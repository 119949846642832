export const createOptionsScene = () => {
  return scene("options", () => {
    let musicVolume = Number(localStorage.getItem("musicVolume")) || 5;
    let sfxVolume = Number(localStorage.getItem("sfxVolume")) || 5;
    let difficulty = localStorage.getItem("difficulty") || "Normal";

    // Add background
    add([
      rect(width(), height()),
      color("000000"), // Dark blue color
      z(-1), // Ensure the background is behind other elements
    ]);

    // Add a gradient overlay
    for (let i = 0; i < height(); i += 2) {
      add([
        rect(width(), 2),
        pos(0, i),
        color(0, 0, 100),
        opacity(1 - i / height()), // Gradually decrease opacity
        z(-0.5), // Place gradient above the background but below other elements
      ]);
    }

    add([
      text("Options", { size: 48 }),
      pos(width() / 2, 60),
      anchor("center"),
    ]);

    const musicVolumeText = add([
      text("Music Volume: " + musicVolume, { size: 24 }),
      pos(width() / 4, 150),
      anchor("left"),
    ]);

    const sfxVolumeText = add([
      text("SFX Volume: " + sfxVolume, { size: 24 }),
      pos(width() / 4, 220),
      anchor("left"),
    ]);

    const difficultyText = add([
      text("Difficulty: " + difficulty, { size: 24 }),
      pos(width() / 4, 290),
      anchor("left"),
    ]);

    // Buttons
    add([
      text("+", { size: 32 }),
      pos(width() / 2 + 50, 150),
      area(),
      anchor("center"),
      "musicUp",
    ]);

    add([
      text("-", { size: 32 }),
      pos(width() / 2 - 50, 150),
      area(),
      anchor("center"),
      "musicDown",
    ]);

    add([
      text("+", { size: 32 }),
      pos(width() / 2 + 50, 220),
      area(),
      anchor("center"),
      "sfxUp",
    ]);

    add([
      text("-", { size: 32 }),
      pos(width() / 2 - 50, 220),
      area(),
      anchor("center"),
      "sfxDown",
    ]);

    add([
      text("Change", { size: 24 }),
      pos(width() / 2 + 100, 290),
      area(),
      anchor("center"),
      "changeDifficulty",
    ]);

    add([
      text("Save", { size: 32 }),
      pos(width() / 3, height() - 100),
      area(),
      anchor("center"),
      "save",
    ]);

    add([
      text("Back", { size: 32 }),
      pos((2 * width()) / 3, height() - 100),
      area(),
      anchor("center"),
      "back",
    ]);

    onClick("musicUp", () => {
      musicVolume = Math.min(musicVolume + 1, 10);
      musicVolumeText.text = "Music Volume: " + musicVolume;
    });

    onClick("musicDown", () => {
      musicVolume = Math.max(musicVolume - 1, 0);
      musicVolumeText.text = "Music Volume: " + musicVolume;
    });

    onClick("sfxUp", () => {
      sfxVolume = Math.min(sfxVolume + 1, 10);
      sfxVolumeText.text = "SFX Volume: " + sfxVolume;
    });

    onClick("sfxDown", () => {
      sfxVolume = Math.max(sfxVolume - 1, 0);
      sfxVolumeText.text = "SFX Volume: " + sfxVolume;
    });

    onClick("changeDifficulty", () => {
      const difficulties = ["Easy", "Normal", "Hard"];
      const currentIndex = difficulties.indexOf(difficulty);
      difficulty = difficulties[(currentIndex + 1) % difficulties.length];
      difficultyText.text = "Difficulty: " + difficulty;
    });

    onClick("save", () => {
      localStorage.setItem("musicVolume", musicVolume);
      localStorage.setItem("sfxVolume", sfxVolume);
      localStorage.setItem("difficulty", difficulty);
      go("menu");
    });

    onClick("back", () => {
      go("menu");
    });

    onHover(
      "musicUp, musicDown, sfxUp, sfxDown, changeDifficulty, save, back",
      (btn) => {
        btn.scale = vec2(1.1);
      }
    );

    onHoverEnd(
      "musicUp, musicDown, sfxUp, sfxDown, changeDifficulty, save, back",
      (btn) => {
        btn.scale = vec2(1);
      }
    );
  });
};
