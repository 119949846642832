export const createMenuScene = () => {
  return scene("menu", () => {
    // Add background
    add([
      rect(width(), height()),
      color("000000"), // Dark blue color
      z(-1), // Ensure the background is behind other elements
    ]);

    // Add a gradient overlay
    for (let i = 0; i < height(); i += 2) {
      add([
        rect(width(), 2),
        pos(0, i),
        color(0, 0, 100),
        opacity(1 - i / height()), // Gradually decrease opacity
        z(-0.5), // Place gradient above the background but below other elements
      ]);
    }

    add([
      text("My Awesome Game", { size: 100 }),
      pos(width() / 2 - 23 * 16, height() / 3.5),
    ]);

    add([
      text("Play", { size: 32 }),
      pos(width() / 2 - 23 * 16, height() / 2),
      area(),
      "button",
    ]);

    add([
      text("Options", { size: 32 }),
      pos(width() / 2 - 23 * 16, height() / 2 + 80),
      area(),
      "button",
    ]);

    onHover("button", (btn) => {
      btn.scale = vec2(1.2);
    });

    onHoverEnd("button", (btn) => {
      btn.scale = vec2(1);
    });

    onClick("button", (btn) => {
      if (btn.text === "Play") {
        go("game");
      } else if (btn.text === "Options") {
        go("options");
      }
    });
  });
};
